/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react"
import dynamic from "next/dynamic"
import { uid } from "uid"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { useGlobalContext } from "../../context/layout-context"

const ImageContentful = dynamic(() => import("../contentful/image"), {
  ssr: false,
})
const TitleUnderlined = dynamic(() => import("../title/title-underlined"), {
  ssr: true,
})
const Title = dynamic(() => import("../title/title"), { ssr: true })
const ButtonTriangle = dynamic(() => import("../buttons/button-triangle"), {
  ssr: false,
})
const Shapes = dynamic(() => import("../bg-pattern/shapes"), { ssr: false })
const Patterns = dynamic(() => import("../bg-pattern/patterns"), { ssr: false })
const VideoLoopPlayer = dynamic(() => import("../video/video-loop-player"), {
  ssr: false,
})

function ImageTextBlockHorizontal({
  classList,
  title,
  content,
  staticContent,
  blockWidthClasses,
  buttons,
  cover,
  titleUnderlined,
  shape,
  shapeClassList,
  pattern,
  background,
  patternClassList,
  titleTag,
  titleClassList,
  contentWidthClasses,
  imageWidthClasses,
  alignContent,
  lineWidth,
  classContainer,
  classColumnsContent,
  wrapImage,
  backgroundColor,
  widthDesktopImg,
  heightDesktopImg,
  heightMobileImg,
  widthMobileImg,
  layoutImgDesktop,
  layoutImgMobile,
  imageClassList,
  coverPosition,
  id,
  source,
  subtitleClasses,
  headingClassList,
  notHaveWrapper,
  videoLoopId,
  videoLoopFile,
  videoLoopSource,
}) {
  const { globalState, updateGlobalState } = useGlobalContext()
  const { isDesktop } = globalState || {}

  const [, setLoopPlaying] = useState(false)

  let textAlign
  let buttonsAlign
  let contentPosition
  let imagePosition

  switch (alignContent) {
    case "Center":
      textAlign = "text-center"
      buttonsAlign = "justify-center"
      break
    case "Center Mobile":
      textAlign = "text-center lg:text-left"
      buttonsAlign = "justify-center lg:justify-start"
      break
    case "Center Desktop":
      textAlign = "text-left lg:text-center"
      buttonsAlign = "justify-start lg:justify-center"
      break
    case "Left":
      textAlign = "text-left"
      buttonsAlign = "justify-start"
      break
    case "Right":
      textAlign = "text-right lg:text-left"
      buttonsAlign = "justify-center"
      break
    default:
      textAlign = "text-left"
      buttonsAlign = "justify-start"
  }

  switch (coverPosition) {
    case "Left":
      contentPosition = "lg:flex-row-reverse"
      imagePosition = wrapImage ? "left-0" : null
      break
    case "Right":
      contentPosition = "lg:flex-row"
      imagePosition = wrapImage ? "right-0" : null
      break
    default:
      contentPosition = "lg:flex-row-reverse"
      imagePosition = wrapImage ? "left-0" : null
  }

  return (
    <article
      className={`overflow-hidden relative ${classList} ${
        blockWidthClasses || "w-full"
      }`}
      style={{ backgroundColor: `${backgroundColor || "#FAF7F1"}` }}
      data-layer-component="image-text-block-horizontal"
    >
      {shape && <Shapes classList={`absolute ${shapeClassList}`} />}
      <div
        className={`${classContainer || "container"} px-0 flex h-full relative`}
      >
        <div
          className={`flex items-stretch w-full flex-wrap ${classColumnsContent} ${contentPosition}`}
        >
          <div
            className={`pt-0 pb-5 lg:py-18 lg:min-h-350px flex items-center ${
              contentWidthClasses || "w-full lg:w-1/2"
            }`}
          >
            <div className={`${textAlign} w-full`}>
              {title && (
                <React.StrictMode>
                  {titleUnderlined ? (
                    <TitleUnderlined
                      tag={titleTag}
                      classList={titleClassList}
                      content={title}
                      colorTitle="text-black"
                      lineWidth={lineWidth}
                      titleAlign={alignContent}
                      headingClassList={headingClassList}
                    />
                  ) : (
                    <Title
                      tag={titleTag}
                      classList={titleClassList}
                      content={title}
                      colorTitle="text-black"
                      titleAlign={alignContent}
                      headingClassList={headingClassList}
                    />
                  )}
                </React.StrictMode>
              )}
              {content && (
                <h4 className={subtitleClasses}>
                  {documentToReactComponents(content.json)}
                </h4>
              )}
              {staticContent && <p>{staticContent}</p>}
              {buttons && (
                <React.StrictMode>
                  {buttons.length > 0 ? (
                    <div className={`${buttonsAlign} btn-container mt-0`}>
                      {buttons.map((button, index) => (
                        <React.Fragment key={uid(16)}>
                          {button.externalUrl !== null && (
                            <ButtonTriangle
                              notHaveWrapper={notHaveWrapper}
                              triangle
                              key={uid(16)}
                              placeholder={button.label}
                              href={button.externalUrl}
                              classList={`${
                                !index % 2 ? "btn-yellow" : "btn-yellow-border"
                              }`}
                              openInNewTab={button.openInNewTab}
                              color={button.buttonColor}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  ) : (
                    <React.StrictMode>
                      {buttons.externalUrl && (
                        <div className={`${buttonsAlign} btn-container mt-0`}>
                          <ButtonTriangle
                            notHaveWrapper={notHaveWrapper}
                            triangle
                            placeholder={buttons.label}
                            href={buttons.externalUrl}
                            classList="btn-yellow"
                            openInNewTab={buttons.openInNewTab}
                            color={buttons.buttonColor}
                          />
                        </div>
                      )}
                    </React.StrictMode>
                  )}
                </React.StrictMode>
              )}
            </div>
          </div>

          {/* Promo Image */}
          {cover && (
            <React.StrictMode>
              {isDesktop ? (
                <div className={`${imageWidthClasses || "w-1/2"}`}>
                  <div className="relative w-full h-full overflow-hidden">
                    {wrapImage ? (
                      <div className={`absolute inset-0 ${imagePosition}`}>
                        <ImageContentful
                          sizes="80vw"
                          src={cover.url || null}
                          width={widthDesktopImg}
                          height={heightDesktopImg}
                          title={cover.captionAlt || cover.title || cover.fileName}
                          layout={layoutImgDesktop}
                          classList={`object-cover object-center ${imageClassList}`}
                        />

                        {(videoLoopId && videoLoopSource) ||
                        (videoLoopFile && videoLoopSource) ? (
                          <VideoLoopPlayer
                            id={videoLoopId || videoLoopFile}
                            source={videoLoopSource}
                            setLoopPlaying={setLoopPlaying}
                            classList="square"
                          />
                        ) : null}
                      </div>
                    ) : id && source ? (
                      <div
                        className={`cursor-pointer absolute inset-0 flex flex-col items-stretch content-center ${imagePosition}`}
                        onClick={
                          id && source
                            ? () => {
                                updateGlobalState({
                                  modalVideoOpen: true,
                                  videoId: id,
                                  videoSrc: source,
                                })
                              }
                            : () => {
                                updateGlobalState({
                                  modalVideoOpen: false,
                                  videoId: null,
                                  videoSrc: null,
                                })
                              }
                        }
                        onKeyPress={
                          id && source
                            ? () => {
                                updateGlobalState({
                                  modalVideoOpen: true,
                                  videoId: id,
                                  videoSrc: source,
                                })
                              }
                            : () => {
                                updateGlobalState({
                                  modalVideoOpen: false,
                                  videoId: null,
                                  videoSrc: null,
                                })
                              }
                        }
                      >
                        <ImageContentful
                          sizes="80vw"
                          src={cover.url || null}
                          width={widthDesktopImg}
                          height={heightDesktopImg}
                          title={cover.captionAlt || cover.title || cover.fileName}
                          layout={layoutImgMobile}
                          classList={`object-cover object-center ${imageClassList}`}
                        />

                        {(videoLoopId && videoLoopSource) ||
                        (videoLoopFile && videoLoopSource) ? (
                          <VideoLoopPlayer
                            id={videoLoopId || videoLoopFile}
                            source={videoLoopSource}
                            setLoopPlaying={setLoopPlaying}
                            classList="square"
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div className={`absolute inset-0  ${imagePosition}`}>
                        <ImageContentful
                          sizes="70vw"
                          src={cover.url || null}
                          width={widthMobileImg}
                          height={heightMobileImg}
                          title={cover.captionAlt || cover.title || cover.fileName}
                          layout={layoutImgMobile}
                          classList={`object-cover object-center ${imageClassList}`}
                        />

                        {(videoLoopId && videoLoopSource) ||
                        (videoLoopFile && videoLoopSource) ? (
                          <VideoLoopPlayer
                            id={videoLoopId || videoLoopFile}
                            source={videoLoopSource}
                            setLoopPlaying={setLoopPlaying}
                            classList="square"
                          />
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              ) : id && source ? (
                <div
                  className={`img-v-center ${
                    imageWidthClasses || "w-full"
                  } relative cursor-pointer overflow-hidden`}
                  onClick={
                    id && source
                      ? () => {
                          updateGlobalState({
                            modalVideoOpen: true,
                            videoId: id,
                            videoSrc: source,
                          })
                        }
                      : () => {
                          updateGlobalState({
                            modalVideoOpen: false,
                            videoId: null,
                            videoSrc: null,
                          })
                        }
                  }
                  onKeyPress={
                    id && source
                      ? () => {
                          updateGlobalState({
                            modalVideoOpen: true,
                            videoId: id,
                            videoSrc: source,
                          })
                        }
                      : () => {
                          updateGlobalState({
                            modalVideoOpen: false,
                            videoId: null,
                            videoSrc: null,
                          })
                        }
                  }
                >
                  <ImageContentful
                    sizes="70vw"
                    src={cover.url || null}
                    width={widthMobileImg}
                    height={heightMobileImg}
                    title={cover.captionAlt || cover.title || cover.fileName}
                    layout={layoutImgMobile}
                    classList={`object-cover object-center absolute inset-0 -inset-x-4 ${imageClassList}`}
                  />

                  {(videoLoopId && videoLoopSource) ||
                  (videoLoopFile && videoLoopSource) ? (
                    <VideoLoopPlayer
                      id={videoLoopId || videoLoopFile}
                      source={videoLoopSource}
                      setLoopPlaying={setLoopPlaying}
                    />
                  ) : null}
                </div>
              ) : (
                <div
                  className={`img-v-center ${
                    imageWidthClasses || "w-full"
                  } relative`}
                >
                  <ImageContentful
                    sizes="70vw"
                    src={cover.url || null}
                    width={widthMobileImg}
                    height={heightMobileImg}
                    title={cover.captionAlt || cover.title || cover.fileName}
                    layout={layoutImgMobile}
                    classList={`object-cover object-center absolute inset-0 -inset-x-4 ${imageClassList}`}
                  />

                  {(videoLoopId && videoLoopSource) ||
                  (videoLoopFile && videoLoopSource) ? (
                    <VideoLoopPlayer
                      id={videoLoopId || videoLoopFile}
                      source={videoLoopSource}
                      setLoopPlaying={setLoopPlaying}
                    />
                  ) : null}
                </div>
              )}
            </React.StrictMode>
          )}
        </div>
      </div>
      {pattern && (
        <Patterns
          background={`${background || "chillis"}`}
          classList={`absolute ${patternClassList}`}
        />
      )}
    </article>
  )
}

export default ImageTextBlockHorizontal
